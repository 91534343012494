import type { AdyenRoute } from '../types'
import { useRoutesUtils } from './useRoutesUtils'

type UrlData = {
  internalLink?: any;
  externalLink?: string;
};

export const useRoutes = (routes: AdyenRoute[], locale: Ref<string>) => {
  const { sanitizeRoutePath, fetchRoutes } = useRoutesUtils()

  if (typeof locale === 'string') {
    console.error('///////// LOCALE shoud be a Ref. Use `wrapLocale` from RouteUtils.')
  }

  /**
   * Get the route data based in the entry id and locale
   */
  const getRouteByEntry = (
    entryId: string
  ) => {
    return routes.find(
      ({ payload }) => payload?.id === entryId && payload?.locale === locale.value
    )

    // Fetch /api/route/entryId
    // getAllroutes // cached
  }

  // const bindRoutesToGetInternalUrl = (routes: AdyenRoute[], locale: string) => {
  //   return (entryId: string) => {
  //     return getInternalUrl(entryId, routes, locale)
  //   }
  // }

  /**
   * Get internal URL to be used in link components
   */
  const getInternalUrl: Function = (
    entryId: string
  ): string => {
    return getRouteByEntry(entryId)?.route || ''
  }

  // const bindRoutesToGetUrl = (routes: AdyenRoute[], locale: string) => {
  //   return (urlData: UrlData) => {
  //     return getUrl(urlData, routes, locale)
  //   }
  // }

  const getUrl: Function = (urlData: UrlData) => {
    if (!urlData) {
      return { url: '', route: null, lazyUrlEndpoint: null }
    }

    const { internalLink, externalLink } = urlData
    // prioritize internal urls over externals
    if (internalLink?.sys?.id) {
      const route = getRouteByEntry(internalLink.sys.id)
      return {
        lazyUrlEndpoint: route?.route ? null : '/api/routes/entry/' + internalLink.sys.id + '?locale=' + locale.value,
        url: route?.route || '',
        route
      }
    }

    if (externalLink) {
      return { url: externalLink, route: null, lazyUrlEndpoint: null }
    }

    return { url: '', route: null, lazyUrlEndpoint: null }
  }

  const getRouteParams = (entryId: string, isHome: boolean) =>
    routes.filter(({ payload }) => payload.id === entryId)
      .reduce((acc, route) => {
        if (route?.payload?.slug) {
          acc[route.payload.localeISO] = { slug: isHome ? '/' : route.payload.slug }
        }
        return acc
      }, {})

  const getDefaultLocaleRoute = (
    path: string,
    defaultLocale: string
  ) => {
    // sanitized route path without tailing slash
    const routePath = sanitizeRoutePath(path)

    // find route by path
    // filter by contentType to avoid conflict with `pagePlaceholder` routes
    const route = routes.find(
      ({ route, payload }) =>
        route === routePath && payload.contentType !== 'pagePlaceholder'
    )

    // if route correspond to default locale return it
    if (route?.payload?.locale === defaultLocale) {
      return route
    }

    // if not, search route using entry id and locale and return it
    return (
      routes.find(
        ({ payload }) =>
          payload?.id === route?.payload?.id &&
          payload?.locale === defaultLocale
      ) || {}
    )
  }

  const getRouteByPath = (path: string) => {
    // sanitized route path without tailing slash
    const routePath = sanitizeRoutePath(path)

    return (
      routes.find(
        ({ route, payload }) =>
          route === routePath && payload.contentType !== 'pagePlaceholder'
      ) || null
    )
  }

  const getRoute = (slug: string) =>
    routes.find(
      ({ payload }) => payload.slug === slug && payload.locale === locale.value
    ) || null

  const getRouteByContentType = (
    slug: string,
    contentType: string
  ) =>
    routes.find(
      ({ payload }) =>
        payload.contentType === contentType &&
        payload.slug === slug &&
        payload.locale === locale.value
    ) || null

  const routeExists = (path: string) => {
    const routePath = sanitizeRoutePath(path)
    return routes.some(
      ({ route, payload }) =>
        route === routePath && payload.contentType !== 'pagePlaceholder'
    )
  }

  return {
    getRouteByEntry,
    getRouteParams,
    getDefaultLocaleRoute,
    getRouteByPath,
    getRoute,
    getRouteByContentType,
    routeExists,
    getInternalUrl,
    getUrl
  }
}
